/*Style.scss*/
/* OPEN SANS */
@font-face {
  font-family: "Open Sans Light";
  src: url("../../assets/fonts/OpenSans-Light.ttf") format("truetype");
}
body {
  font-family: "Open Sans Light";
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.7em;
}

/*BOOTSTRAP*/
a, a:hover {
  text-decoration: none;
}

header section {
  padding: 2em 4em;
  text-align: center;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

#coordonnees {
  max-width: 570px;
}

.App {
  padding-left: 15em;
}

header .App-logo {
  width: 75px;
}

#logo a {
  margin: auto;
  max-width: 175px;
  display: block;
  height: auto;
  overflow: hidden;
  border-radius: 50%;
}
#logo a img {
  vertical-align: bottom;
  width: 100%;
  height: auto;
}

/*
#compentences{text-align: center;}

#compentences article li{
    text-align:left;
}
*/
.private {
  display: none;
}

#outils ul {
  padding: 0;
}
#outils ul li {
  list-style: none;
}

hr, hr:not([size]) {
  height: 2px;
  width: 100%;
  background: rgb(53, 53, 53);
  display: block;
  opacity: 1;
  border: none;
  position: relative;
}

main {
  overflow: hidden;
  position: relative;
  margin: auto;
  padding: 0;
}

@keyframes bg-fx {
  from {
    transform: rotate(0deg);
    width: 80%;
  }
  to {
    transform: rotate(-4deg);
    width: 82%;
  }
}
@keyframes bg-fx2 {
  from {
    width: 98%;
  }
  to {
    width: 100%;
  }
}
section {
  padding: 0em 5em 5em 5em;
  margin: auto;
  max-width: 100%;
  position: relative;
}

.row {
  --bs-gutter-x: 6em;
  --bs-gutter-y: 2em;
  margin-right: calc(var(--bs-gutter-x) / -6);
  margin-left: calc(var(--bs-gutter-x) / -6);
}

aside {
  padding: 2em;
}

/* TITRES */
h1 {
  text-transform: uppercase;
}

h2 {
  font-size: 1.7em;
  font-weight: 300;
  margin-bottom: 2em;
  text-transform: uppercase;
}
h2 svg {
  margin-right: 1.3rem;
}

h3 {
  font-size: 1.5rem;
}

ul {
  padding-left: 0;
}

li {
  list-style: none;
}

#profil {
  text-align: center;
  margin: 5em auto;
  padding: 5em 2em;
}
#profil p {
  font-size: 1.1em;
  letter-spacing: 0.2em;
}

#sites {
  text-align: center;
  margin: auto;
}
#sites ul {
  padding-left: 0;
}

#formations {
  position: relative;
}
#formations ul {
  position: relative;
}
#formations li {
  position: relative;
  margin: 1em 0;
}

#centres-interets h2 {
  text-align: left;
}

#experiences h2 {
  text-align: center;
  display: block;
  width: 100%;
}
#experiences article {
  position: relative;
  padding-bottom: 2em;
}
#experiences article hr {
  position: absolute;
  bottom: 0;
  width: calc(100% - 6em);
}
#experiences small {
  margin-bottom: 1.3em;
  display: block;
}

#langages {
  text-align: center;
}

.carousel-indicators [data-bs-target] {
  background: rgb(255, 255, 255);
  height: 7px;
  border-radius: 7px;
  padding: 0;
  border: none;
}

.carousel-inner {
  text-align: center;
}

progress {
  display: block;
  width: 100%;
  background: transparent;
}

a {
  text-decoration: underline;
}

.badge {
  padding: 0.4rem 0.8rem;
  margin-right: 0.6rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 300;
  border-radius: 1em;
}
.badge svg {
  margin-right: 1em;
}

.outils .badge {
  padding: 0.4rem 0.8rem;
  margin-right: 0.6rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 300;
}
.outils p {
  margin-bottom: 0;
}

@keyframes op {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.7;
  }
}
@media screen and (max-width: 720px) {
  .App {
    padding-left: 0;
  }
  header #logo {
    order: 1;
  }
  header #adresse {
    order: 2;
  }
  header #tel {
    order: 3;
  }
  h2 {
    letter-spacing: 0.2em;
  }
}
@media screen and (max-width: 992px) {
  #experiences h2 {
    text-align: left;
    padding: 0 2em;
  }
}
/* colors .scss */
body {
  background-color: #292929;
  color: #fff;
}

a, a:hover {
  color: #fff;
}

.navbar {
  background: #3E3E3E;
}

.nav-link {
  color: #fff;
}

.navbar-nav .nav-link.active {
  color: #97b415;
}

.navbar-brand {
  color: #fff;
}

.nav-link:hover, .nav-link:focus {
  color: #fff;
}

.card {
  background: #3E3E3E;
}

.btn-primary {
  font-weight: normal;
  background: #b0d800 !important;
  border-color: #b0d800 !important;
  color: #292929 !important;
  text-transform: uppercase;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background: #97b415 !important;
  border-color: #97b415 !important;
  color: #292929 !important;
}

.btn-secondary {
  background: #860fa7;
  border-color: #860fa7;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
  background: #a125c4;
  border-color: #a125c4;
}

.bg-dark {
  background: #292929 !important;
}

.bg-secondary {
  background: #3E3E3E !important;
}

.bg-primary {
  background: #860fa7 !important;
}

.bg-success-0 {
  --bs-bg-opacity: 0;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

#sidebarMenu {
  background: #3E3E3E !important;
}

.page header {
  border: #005a9c solid 2px;
  background: #005a9c;
  color: white;
  text-align: center;
}

.page header .title {
  font-size: 2.5em;
  font-weight: bold;
  font-family: serif;
}

.page header .tagline {
  font-style: italic;
}

.page .main {
  padding: 1em 5% 5em;
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
}

.page footer {
  border: #005a9c solid 2px;
  background: #005a9c;
  font-family: serif;
  color: white;
  font-style: italic;
  padding-left: 1em;
}

.page nav {
  margin: 0;
  padding: 0;
  border: 2px solid #eee;
}

.menubar-navigation {
  margin: 0;
  padding: 2px;
  font-size: 110%;
  list-style: none;
  background-color: #eee;
  border: #eee solid 1px;
}

.menubar-navigation li {
  margin: 0;
  padding: 0;
  border: 0 solid black;
  list-style: none;
}

.menubar-navigation > li {
  display: inline-block;
  position: relative;
}

.menubar-navigation > li li {
  display: block;
}

.menubar-navigation > li > [role=menuitem] {
  display: inline-block;
  margin: 2px;
  padding: 4px;
  padding-bottom: 8px;
  background-color: #eee;
  border: 0 solid #eee;
  color: black;
}

.menubar-navigation [role=separator] {
  padding-top: 3px;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cline x1='0' y1='6' x2='12' y2='6' style='stroke:black;stroke-width:1' /%3E%3C/svg%3E%0A");
  background-size: 10px 10px;
  background-position: center;
  background-repeat: repeat-x;
}

.menubar-navigation [role=menu] [role=menuitem],
.menubar-navigation [role=menu] [role=separator] {
  display: block;
  width: 12em;
  margin: 2px;
  padding: 4px;
  padding-left: 8px;
  background-color: #eee;
  border: 0 solid #eee;
  color: black;
}

.menubar-navigation [role=menuitem] svg {
  fill: currentcolor;
  stroke: currentcolor;
}

.menubar-navigation [role=menuitem] svg.down {
  padding-left: 0.125em;
}

.menubar-navigation [role=menuitem] svg.right {
  position: absolute;
  padding-top: 0.35em;
  right: 0.75em;
}

.menubar-navigation [role=menuitem][aria-expanded=true] svg.down {
  transform: rotate(180deg);
}

.menubar-navigation [role=menuitem][aria-expanded=true] svg.right {
  transform: rotate(90deg) translate(5px, -5px);
}

.menubar-navigation [role=menu] {
  display: none;
  position: absolute;
  margin: 0;
  padding: 0;
  padding: 7px 4px;
  border: 2px solid #034575;
  background-color: #eee;
}

.menubar-navigation [role=group] {
  margin: 0;
  padding: 0;
}

/* aria-current styling */
.menubar-navigation > li > [role=menuitem][aria-current],
.menubar-navigation > li > [role=menuitem].aria-current-path {
  padding-bottom: 2px;
  border-bottom: 4px solid #034575;
}

.menubar-navigation [role=menu] [role=menuitem].aria-current-path,
.menubar-navigation [role=menu] [role=menuitem][aria-current] {
  padding-left: 4px;
  border-left: 4px solid #034575;
}

/* focus styling */
.menubar-navigation.focus {
  padding: 0;
  border: #034575 solid 3px;
}

.menubar-navigation > li > [aria-expanded=true],
.menubar-navigation > li > [role=menuitem]:focus,
.menubar-navigation > li > [role=menuitem]:hover {
  outline: none;
  background-color: #ccc;
}

.menubar-navigation > li > [role=menuitem]:focus,
.menubar-navigation > li > [role=menuitem]:hover {
  padding: 2px;
  padding-bottom: 4px;
  border: 2px solid #034575;
}

.menubar-navigation [role=menu] [aria-expanded=true],
.menubar-navigation [role=menu] [role=menuitem]:focus,
.menubar-navigation [role=menu] [role=menuitem]:hover {
  outline: none;
  background-color: #ccc;
}

.menubar-navigation [role=menu] [role=menuitem]:focus,
.menubar-navigation [role=menu] [role=menuitem]:hover {
  padding: 2px;
  padding-left: 6px;
  border: 2px solid #034575;
}

.menubar-navigation > li > [aria-expanded=true].aria-current-path,
.menubar-navigation > li > [role=menuitem].aria-current-path:focus,
.menubar-navigation > li > [role=menuitem].aria-current-path:hover,
.menubar-navigation > li > [role=menuitem][aria-current]:focus,
.menubar-navigation > li > [role=menuitem][aria-current]:hover {
  padding-bottom: 2px;
  border-bottom: 4px solid #034575;
}

.menubar-navigation [role=menu] [aria-expanded=true].aria-current-path,
.menubar-navigation [role=menu] [role=menuitem].aria-current-path:focus,
.menubar-navigation [role=menu] [role=menuitem].aria-current-path:hover,
.menubar-navigation [role=menu] [role=menuitem][aria-current]:focus,
.menubar-navigation [role=menu] [role=menuitem][aria-current]:hover {
  padding-left: 4px;
  border-left: 4px solid #034575;
}

