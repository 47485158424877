/* colors .scss */   

$color1: #97b415;
$color1-hover: #b0d800;

$color2: #292929; 

$color3: #3E3E3E;

$color4: #860fa7;
$color4-hover: #a125c4;

$color5: #fff;

body{background-color:$color2;
color:$color5}
a, a:hover{color:$color5}
.navbar{background: $color3;  }
.nav-link { color: $color5}
.navbar-nav .nav-link.active{color: $color1}
.navbar-brand{color: $color5}
.nav-link:hover, .nav-link:focus{color: $color5}

 .card{background:$color3}

.btn-primary {font-weight: normal;
    

    background: $color1-hover!important; 
    border-color: $color1-hover!important;
    color: $color2!important;

    text-transform: uppercase;} 

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {  
    background:$color1!important; 
    border-color: $color1!important;
    color: $color2!important;

   }


.btn-secondary {
    background: $color4; 
    border-color: $color4;
 } 

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {  background: $color4-hover; border-color: $color4-hover;}

.bg-dark{background:$color2!important} 
.bg-secondary{ background: $color3!important}
.bg-primary{ background: $color4!important}
 
.bg-success-0 {
    --bs-bg-opacity: 0;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  }
 #sidebarMenu{ background: $color3!important}