
/*Style.scss*/

/* OPEN SANS */ 
@font-face {
    font-family: "Open Sans Light";
    src: url("../../assets/fonts/OpenSans-Light.ttf")  format('truetype');
           
   }
     
  
   body { 
     font-family: "Open Sans Light"; margin:0; 
     font-size:1.1rem; line-height:1.7em;   
 }
  
 
/*BOOTSTRAP*/ 
 a, a:hover{text-decoration: none;}
 
header section{  padding: 2em 4em; 
    
    text-align: center; 
}
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
#coordonnees{max-width:570px}

.App{padding-left:15em}

header  .App-logo {width:75px;} 

  #logo a {   
    margin:auto;
    max-width: 175px;
    display:block;
    height:auto; 
    overflow: hidden;
    border-radius: 50%;

    img{ vertical-align: bottom; width: 100%; height: auto;  }
}

 



/*
#compentences{text-align: center;}

#compentences article li{
    text-align:left;
}
*/

.private{display: none;}

#outils{ 
        ul { padding:0;}
         ul li{ list-style: none;}
        }


hr, hr:not([size]){ 
    height: 2px;
    width: 100%; 
    background:rgb(53, 53, 53);
    display:block;   opacity:1;
    border:none;
    position:relative; 
}
 
main {    overflow: hidden;
  position: relative;
        margin:auto; 
      padding:0;
} 
  @keyframes bg-fx {
    from {
        transform:  rotate(0deg); 
      width: 80%;
    }
  
    to {
        transform:  rotate(-4deg); 
      width: 82%;
    }
  }
 


  @keyframes bg-fx2 {
      from {
        
        width: 98%;
      }
    
      to {
        
        width: 100%;
      }
    }

   
       
    
section{ padding:0em 5em 5em 5em; margin: auto; max-width: 100%; position: relative;}

.row{
    --bs-gutter-x: 6em;
    --bs-gutter-y: 2em;
    margin-right: calc(var(--bs-gutter-x)/ -6);
    margin-left: calc(var(--bs-gutter-x)/ -6);

}



aside{
    padding: 2em;
}

/* TITRES */
h1{  text-transform: uppercase;}
h2{  
    font-size: 1.7em;
    font-weight: 300; margin-bottom : 2em; 
    text-transform: uppercase;  
    
    svg{margin-right:1.3rem;}
} 
h3{font-size:1.5rem}
 

  
ul {padding-left: 0;}
li{list-style: none;}

#profil{text-align: center;  margin:5em auto; padding: 5em 2em;

  p{    font-size: 1.1em;
    letter-spacing: 0.2em;}} 

#sites {text-align:center; margin:auto; 
  ul{padding-left:0;}
} 

#formations { position:relative;

  ul{position: relative}
  li{position:relative; margin: 1em 0;}
} 
 
#centres-interets h2 {text-align:left; }
 
 
#experiences {
               h2{text-align:center;     display: block;
              width: 100%;}
              article{position:relative;  padding-bottom:2em;}
              article hr{position:absolute;bottom:0; width:calc(100% - 6em);}
              small {margin-bottom: 1.3em; display: block;}
            }
 
 


#langages {text-align: center;}


.carousel-indicators [data-bs-target]{ background:rgb(255, 255, 255); height: 7px; border-radius: 7px; padding:0; border:none;}
.carousel-inner{
text-align:center;
}

progress{display:block;width:100%; background:transparent }

a{ text-decoration: underline;}  
.badge{
  svg{margin-right:1em}
  padding: 0.4rem 0.8rem;
  margin-right: 0.6rem;
  margin-bottom:1rem;
  font-size: 1rem;
  font-weight: 300;
  border-radius: 1em;
}
.outils{
  .badge{
    padding: 0.4rem 0.8rem;
    margin-right: 0.6rem;
    margin-bottom:1rem;
    font-size: 1rem;
    font-weight: 300;
  }
  p{margin-bottom:0}
}
 
@keyframes op {
  from {
     opacity:1
   
  }

  to {
    opacity:0.7
    
  }
}
 

 @media screen and (max-width: 720px) {

.App{padding-left:0;}


    header{ #logo {order:1; }
            #adresse{order:2}
            #tel{order:3;  } }
    

    h2{letter-spacing:0.2em} 

}

@media screen and (max-width: 992px) {

  

      #experiences h2 {text-align:left; padding: 0 2em}

}
 
 
@import "./colors.scss";
@import "./navigation.scss";

 
 
 

   
